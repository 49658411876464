<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center">
            <b-spinner v-if="loading"/>
        </div>
        <slot v-if="showEmpty" name="empty">
            <EmptyPlaceholder icon="info">
                {{ emptyMessage }}
            </EmptyPlaceholder>
        </slot>
        <div v-show="!loading && !showEmpty">
            <div class="d-flex justify-content-between">
                <div>
                    <slot name="header"/>
                </div>
                <Pagination v-if="pagination" v-model="page" :number-of-pages="numberOfPages"/>
            </div>

            <div class="table-container">
                <div :class="{'table-responsive': this.responsive}">
                    <table class="table" ref="table"
                           :class="{
                                'table--compact': compact,
                                'table--fixed-headers': fixedHeaders,
                                'table--pagination': pagination,
                                'table--clickable-rows': clickableRows,
                                'table--transparent-headers': transparentHeaders,
                                'table--mini': mini}">
                        <thead ref="thead">
                        <tr>
                            <th v-if="hasExpandedSlot" class="expanded-cell"/>
                            <th v-if="showCheckboxes" class="checkbox-cell">
                                <b-checkbox v-model="toggleAllSelectedValue"/>
                            </th>
                            <slot name="headers">
                                <TableHeader v-for="(column, columnIndex) in columns"
                                             :key="'header-' + columnIndex"
                                             :shrink="column.shrink"
                                             :center="column.center"
                                             :right="column.right">
                                    {{ column.header }}
                                </TableHeader>

                                <TableHeader v-if="actions && actions.length" right shrink/>
                            </slot>
                        </tr>
                        </thead>
                        <tbody>
                        <slot>
                            <template v-if="items">
                                <template v-for="(item, index) in visibleItems">
                                    <template v-if="columns && columns.length">
                                        <TableRow :status="getStatus(item)"
                                                  :value="showCheckboxes ? selected.includes(item.id) : undefined"
                                                  :expanded="hasExpandedSlot ? visibleExpanded.includes(index) : undefined"
                                                  @input="setSelected(item, $event)"
                                                  @click.native="$emit('row-clicked', { event: $event, item })"
                                                  @toggleExpanded="toggleExpanded(index)"
                                        >
                                            <TableCell v-for="(column, columnIndex) in columns"
                                                       :key="'column-'+columnIndex"
                                                       :right="column.right"
                                                       :center="column.center"
                                            >
                                                <TableColumnRenderer :class="{'font-weight-bold': column.bold || false}"
                                                                     :item="item"
                                                                     :type="column.type"
                                                                     :value-config="column.value"
                                                                     :options="column.options"/>
                                                <template #sub v-if="column.sub">
                                                    <TableColumnRenderer
                                                        :item="item"
                                                        :value-config="column.sub"
                                                        :options="column.optionsSub"
                                                    />
                                                </template>
                                            </TableCell>
                                            <TableCell v-if="actions && actions.length" right>
                                                <div class="d-flex justify-content-end">
                                                    <template v-for="action in actions">
                                                        <template v-if="action.dropdown">
                                                            <b-dropdown right size="sm" :no-caret="!action.label"
                                                                        :toggle-class="{'p-2': action.icon && !action.label}">
                                                                <template #button-content>
                                                                    <i v-if="action.icon"
                                                                       :class="{[action.icon]: true, 'mr-0': !action.label}"/>
                                                                    {{ action.label }}
                                                                </template>
                                                                <b-dropdown-item v-for="dropdownAction in action.dropdown(item)" :key="dropdownAction.name"
                                                                                 v-if="!action.show || action.show(item)"
                                                                                 @click="$emit(action.name, {item: item, action: dropdownAction.name})"
                                                                                 :disabled="!dropdownAction.disabled ? false : dropdownAction.disabled(item)"
                                                                                 :variant="dropdownAction.variant"
                                                                >
                                                                    <i v-if="dropdownAction.icon"
                                                                       :class="{[dropdownAction.icon]: true, 'lely-icon-middle': true, 'mr-0': !dropdownAction.label}"/>
                                                                    {{ dropdownAction.label }}
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </template>
                                                        <template v-else>
                                                            <b-btn v-if="!action.show || action.show(item)"
                                                                   :key="action.name"
                                                                   :variant="action.variant || 'secondary'"
                                                                   :disabled="!action.disabled ? false : action.disabled(item)"
                                                                   size="sm"
                                                                   class="ml-2"
                                                                   :class="{'p-2': action.icon && !action.label}"
                                                                   @click="$emit(action.name, item)"
                                                            >
                                                                <i v-if="action.icon" :class="{[action.icon]: true, 'mr-0': !action.label}"/>
                                                                <template v-if="action.label">
                                                                    {{ action.label }}
                                                                </template>
                                                            </b-btn>
                                                        </template>
                                                    </template>
                                                </div>
                                            </TableCell>
                                        </TableRow>

                                        <tr v-if="visibleExpanded.includes(index)">
                                            <td :colspan="colspan" class="expanded-row">
                                                <slot v-if="visibleExpanded.includes(index)" name="expanded" :item="item" :index="index"/>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <slot name="item"
                                              :item="item"
                                              :index="index"
                                              :isChecked="showCheckboxes ? selected.includes(item.id) : undefined"
                                              :setChecked="checked => setSelected(item, checked)"
                                              :isExpanded="visibleExpanded.includes(index)"
                                              :toggleExpanded="() => toggleExpanded(index)"/>

                                        <slot v-if="visibleExpanded.includes(index)" name="expanded" :item="item" :index="index"/>
                                    </template>
                                </template>
                            </template>
                        </slot>
                        <slot name="footers"/>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <Pagination v-if="pagination" v-model="page" @input="scrollToTop()" :number-of-pages="numberOfPages"/>
            </div>

            <slot name="footer"/>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch, } from 'vue-property-decorator';
import LelyPagination from '../Pagination.vue';
import LelyEmptyPlaceholder from '../EmptyPlaceholder.vue';
import LelyTableRow from './TableRow.vue';
import TableHeader from './TableHeader.vue';
import TableCell from './TableCell.vue';
import TableColumnRenderer from './TableColumnRenderer.vue';
import { LelyTableActionsConfig, LelyTableColumnsConfig, LelyTableRowStatus } from '../../core/LelyTableTypes';

const PAGINATION_SIZE_OF_ITEMS = 50;

interface HasId {
    id: number;
}

@Component({
    components: {
        TableColumnRenderer,
        TableCell,
        TableHeader,
        TableRow: LelyTableRow,
        Pagination: LelyPagination,
        EmptyPlaceholder: LelyEmptyPlaceholder,
    }
})
export default class LelyTable<T extends HasId> extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    compact!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    mini!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    fixedHeaders!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    responsive!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    pagination!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    loading!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    transparentHeaders!: boolean;

    @Prop({
        type: String,
        default: 'No data was found',
    })
    emptyMessage!: string;

    @Prop({type: Number})
    initialId!: number;

    @Prop()
    items!: T[];

    @Prop()
    columns!: LelyTableColumnsConfig<T>;

    @Prop()
    actions!: LelyTableActionsConfig<T>;

    @Prop()
    tableRowStatus!: (item: T) => LelyTableRowStatus;

    @Prop({
        type: Boolean,
    })
    clickableRows!: boolean;

    @Model('input')
    selected?: number[];

    page = 1;

    visibleExpanded: number[] = [];

    intiallyShown = false;

    toggleAllSelectedValue = false;

    mounted(): void {
        this.initStuckObserver();
    }

    get showCheckboxes(): boolean {
        return this.selected !== undefined;
    }

    get hasExpandedSlot(): boolean {
        return this.columns && !!this.$scopedSlots.expanded;
    }

    updated(): void {
        if (this.loading || this.intiallyShown || !this.items || this.items.length === 0) {
            return;
        }

        if (this.initiallyShownVisibleItemIndex) {
            this.intiallyShown = true;
            this.visibleExpanded.push(this.initiallyShownVisibleItemIndex);

            this.$nextTick(() => {
                if (!this.initiallyShownVisibleItemIndex) {
                    return;
                }

                const item = (this.$refs.table as HTMLTableElement).querySelector(`tbody tr:nth-child(${this.initiallyShownVisibleItemIndex + 1})`);
                if (item) {
                    item.scrollIntoView({
                        block: 'center',
                        inline: 'center',
                    });
                }
            });
        }
    }

    get initiallyShownItemIndex(): number | null {
        if (!this.initialId) {
            return null;
        }

        // @ts-ignore
        const index = this.items.findIndex(item => item.hasOwnProperty('id') && item.id === this.initialId);

        return index > -1 ? index : null;
    }

    get initiallyShownVisibleItemIndex(): number | null {
        if (!this.initialId) {
            return null;
        }

        return this.visibleItems.findIndex(item => item.hasOwnProperty('id') && item.id === this.initialId);
    }

    @Watch('loading')
    initStuckObserver(): void {
        if (!this.fixedHeaders || this.loading || !this.$refs.thead) {
            return;
        }

        const observer = new IntersectionObserver(
            ([e]) => {
                e.target.toggleAttribute('stuck', e.intersectionRatio < 1);
            },
            {
                threshold: [1],
                rootMargin: '-60px 0px 0px 0px',
            },
        );

        observer.observe(this.$refs.thead as Element);
    }

    toggleExpanded(index: number): void {
        if (this.visibleExpanded.includes(index)) {
            this.visibleExpanded = this.visibleExpanded.filter(item => item !== index);
        } else {
            this.visibleExpanded.push(index);
        }

        this.$emit('expanded', this.visibleExpanded.includes(index) ? this.visibleItems[index] : null);
    }

    get visibleItems(): T[] {
        if (!this.pagination) {
            return this.items;
        }

        if (!this.items) {
            return [];
        }

        return this.items.slice((this.page - 1) * PAGINATION_SIZE_OF_ITEMS, ((this.page - 1) * PAGINATION_SIZE_OF_ITEMS) + PAGINATION_SIZE_OF_ITEMS);
    }

    get numberOfPages(): number {
        if (!this.items) {
            return 0;
        }

        return Math.ceil(this.items.length / PAGINATION_SIZE_OF_ITEMS);
    }

    get showEmpty(): boolean {
        return !this.loading && (this.items && this.items.length === 0);
    }

    @Watch('items')
    itemsUpdated(): void {
        if (this.showCheckboxes) {
            this.toggleAllSelectedValue = false;
        }

        if (!this.pagination || !this.initiallyShownItemIndex) {
            this.page = 1;
            return;
        }

        this.page = Math.ceil(this.initiallyShownItemIndex / PAGINATION_SIZE_OF_ITEMS);
    }

    expandAll(): void {
        this.visibleExpanded = [...Array(this.visibleItems.length).keys()];
    }

    @Watch('items')
    collapseAll(): void {
        this.visibleExpanded = [];
    }

    @Watch('page')
    pageUpdated(): void {
        this.collapseAll();
    }

    scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    setSelected(item: T, checked: boolean): void {
        let items = [...this.selected || []];

        if (checked) {
            items.push(item.id);
        } else {
            items = items.filter(selected => selected !== item.id);
        }

        this.$emit('input', items);
    }

    @Watch('toggleAllSelectedValue')
    toggleAllSelected(checked: boolean): void {
        if (checked) {
            this.$emit('input', this.items.map(item => item.id));
        } else {
            this.$emit('input', []);
        }
    }

    getStatus(item: T): LelyTableRowStatus | undefined {
        return this.tableRowStatus ? this.tableRowStatus(item) : undefined;
    }

    get colspan(): number {
        return (this.hasExpandedSlot ? 1 : 0) + (this.showCheckboxes ? 1 : 0) + (this.columns?.length || 0) + (this.actions?.length || 0);
    }
}

</script>

<style lang="scss">
@import "../../assets/lely-red-rules/lely-styling/base/variables";

.table-container {
    position: relative;
}

.table > thead > tr > th {
    padding-top: 12px !important;
    vertical-align: middle !important;
    background: white !important;

    .custom-control-label {
        width: 0 !important;
    }
}

.table--transparent-headers > thead > tr > th {
    background: transparent !important;
}

.table > tbody > tr > td {
    white-space: nowrap !important;
}

.table > thead > tr > th {
    z-index: 1;
}

.table .expanded-cell {
    width: 30px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.table tr.is-expanded td {
    border-bottom: 0;
}

.table tr.expanded-row td {
    border-top: 0;
    padding: 10px !important;
}

.table--compact {
    > tbody > tr > td,
    > tfoot > tr > td {
        height: 48px;
        min-height: 48px;
    }
}

.table--mini {
    > thead > tr > th,
    > tbody > tr > td,
    > tfoot > tr > td {
        height: 28px;
        min-height: 28px;
        font-size: 13px;
        padding: 4px 10px !important;
    }
}

.table--clickable-rows > tbody > tr > td {
    cursor: pointer;
}

.table td.compact {
    height: 48px !important;
    min-height: 48px !important;
}

.table--fixed-headers {
    & > thead {
        th {
            position: sticky;
            top: 57px;
            padding-top: 15px !important;
            z-index: 1;
        }

        &[stuck] th {
            background: white;
            border-bottom: 1px solid $color-dairy;
        }
    }

    tbody td, tbody th {
        position: static !important;
    }

    tbody table {
        position: static !important;
    }
}
</style>
